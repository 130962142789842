<template>
  <div>
    <v-tabs v-model="activeTab" centered>
      <v-tab
        v-for="tab of KRDNADashboardTabs"
        :key="tab.name"
        :to="tab.route"
        @click="setActiveTab(tab.route)"
        exact
      >
        {{ $t(tab.name) }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import { authComputed } from './../store/helpers.js'

export default {
  data() {
    return {
      activeTab: `/krdnadashboard/`,
    }
  },
  methods: {
    setActiveTab(route) {
      this.activeTab = route
    },
  },
  mounted() {
    // this.$store.dispatch('mtpl/getClaims', {
    //   pipelineId: 4,
    //   orderBy: 'reportedDate',
    // })
  },
  computed: {
    ...authComputed,
  },
}
</script>

<style lang="scss" scoped></style>
